// 在 Vue 组件或插件中
import axios from 'axios';

// 创建 Axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 从 localStorage 或其他存储中获取 token
    const token = localStorage.getItem('token');
    console.log(token);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // 在请求头中设置 token
    }
    return config;
  },
  error => {
    // 处理请求错误
    console.log(error); // for debug
    return Promise.reject(error);
  }
);


// 导出 Axios 实例供其他组件使用
export default service;