<template>
  <body class="login-msg" id="poster">
  <div class="login-content">
    <el-form class="login-container" label-position="left" label-width="auto" style="max-width: 600px">
      <h3 class="login-title">账号登录</h3>
      <el-form-item>
        <el-input type="text" autocomplete="off" placeholder="账号" v-model="form.name" />
      </el-form-item>
      <el-form-item>
        <el-input type="password" autocomplete="off" placeholder="密码" v-model="form.pwd" />
      </el-form-item>

      <el-form-item style="width: 100%">
        <el-button style="width: 48%" type="primary" @click="Login">登录</el-button>
        <el-button style="width: 48%" @click="toRegister">注册</el-button>
      </el-form-item>
    </el-form>
  </div>

  </body>

</template>

<script>
import {userLogin} from "@/api/comment.js";


export default {
  name: 'HomeView',
  data() {
    return {
      form: {
        name: '',
        pwd: '',
      },
    }
  },
  methods: {
    Login() {
      if (this.form.name === '') {
        this.$message({
          message: '用户名不能为空',
          type: 'error'
        })
      } else if (this.form.pwd === '') {
        this.$message({
          message: '密码不能为空',
          type: 'error'
        })
      } else {
        userLogin(this.form).then(res => {
          if (res.code === 200) {
            localStorage.setItem('token', res.data);
            this.$message({
              message: '登录成功',
              type: 'success'
            })
            this.form = {}
            this.$router.push('/home')
          } else if(res.code !== 200) {
            this.$message({
              message: '登录失败',
              type: 'error'
            }

            )

          }
          else {
            this.$message({
              type:'error',
              message:'链接超时'
            })
          }
        }).catch(err=>{
          this.$message({
            type:'error',
            message:err
          })
        })
      }
    },
    toRegister(){
      this.$router.push('/enroll')
    },


  }

}
</script>

<style>

  #poster{
    background-position: center;
    height: 100%;
    width: 100%;
    bgsize: cover;
    position: fixed;
  }
  body{
    margin: 0px;
    padding: 0px;

  }
  .login-content{
    background-color: rgb(255, 255, 255,0.8);
    height: 100%;
    width: 700px;
    display: flex;
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 50% 0px 0px 50%;
  }
  .login-container{
    border-radius: 15px;
    background-clip: padding-box;
    margin: 40% auto;
    width: 350px;
    height: 30%;
    padding: 35px 35px 15px 30px;
    background-color: rgb(94, 166, 92,0.1) !important;
    border: 1px solid #eaeaee;
    box-shadow: 0 0 25px #8fe809

  }
  .login-title{
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }
.login-msg{
  background-image: url("../assets/login.jpg");
  background-size: 100% 100%;
  height: 100vh;
  min-width: 1000px;
  background-repeat: no-repeat;
}
.verification{
  width: 100%;
  display: flex;
  position: relative;
}
.verification-img{
  width: 100px;
  height: 30px;
  position: absolute;
  right: 30px;
  border-radius: 3px;
}
.verification-input{
  width: 100%!important;
}

</style>
