import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'


const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/enroll',
    name: 'enroll',
    component: () => import(/* webpackChunkName: "about" */ '../views/EnrollView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    children: [
      {
        path: '/environment',
        name: 'environment',
        component: () => import(/* webpackChunkName: "about" */ '../views/EnvironmentView.vue')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "about" */ '../views/UserView.vue')
      },
      {
        path: '/abnormal',
        name: 'abnormal',
        component: () => import(/* webpackChunkName: "about" */ '../views/AbnormalView.vue')
      },
      {
        path: '/controller',
        name: 'controller',
        component: () => import(/* webpackChunkName: "about" */ '../views/ControllerView.vue')
      },
      {
        path: '/realTime',
        name: 'RealTimeView',
        component: () => import(/* webpackChunkName: "about" */ '../views/RealTimeView.vue')
      },
      {
        path: '/evDataAna',
        name: 'EvDataAnaView',
        component: () => import(/* webpackChunkName: "about" */ '../views/EvDataAnaView.vue')

      },
      {
        path: '/abDataAna',
        name: 'AbDataAnaView',
        component: () => import(/* webpackChunkName: "about" */ '../views/AbDataAnaView.vue')

      }

    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
