import service from "../service/service.js";

/**
 * 用户登录
 * @param {Object} data - 用户的登录信息，包含名字、密码、验证码等。
 * @returns {Promise} - 返回一个Promise，解析后的值为登录响应数据。
 */
export async function userLogin(data) {
  try {
    const response = await service.post('/springboot/user/login', data);
    return response.data;
  } catch (error) {
    // 处理错误，例如输出错误信息或返回特定的错误代码
    console.error('登录失败:', error);
    throw error;
  }
}
/**
 * 查找用户名是否存在
 * @param {Object} name 用户名。
 * @returns {Promise} - 返回一个带code的数据能判断是否成功。
 */
export async function getUserByName(name)  {
        const response = await service.get('/springboot/user/getUserByName', {
            params: {
              name: name
            }
          })
          return response.data;
  }


/**
 * 查找手机号是否存在
 * @param {Object} phone 手机号。
 * @returns {Promise} - 返回一个带code的数据能判断是否成功。
 */
export async function getUserByPhone(phone)  {
    
        const response = await  service.get('/springboot/user/getUserByPhone', {
            params: {
              phone: phone
            }
          })
          return response.data;
  }


/**
 * 用户注册
 * @param {Object} data - 用户的注册信息，包含名字、密码、邮箱等。
 * @returns {Promise} - 返回一个Promise，解析后的值为登录响应数据。
 */
export async function userEnroll(data) {
    try {
      const response = await service.post('/springboot/user/enroll', data);
      return response.data;
    } catch (error) {
      // 处理错误，例如输出错误信息或返回特定的错误代码
      console.error('注册失败:', error);
      throw error;
    }
  }

/**
 * 获取验证码
 * @returns {Promise} - 返回是否发送成功
 */
export async function getVerificationCode(Phone){
  console.log(Phone);

  const response = await  service.get('/springboot/user/sendCode', {
    params: {
      phone: Phone
    }
  })
  return response.data;
}

/**
 * 获取环境数据
 */
export async function getEvDate(page,size){

    const response = await  service.get('/springboot/data/getEvData',{
        params:{
            page: page,
            size: size
        }

    })
    return response.data;
}

/**
 * 获取异常数据
 */

export async function getAbDate(page,size){

    const response = await  service.get('/springboot/data/getAbData',{
        params:{
            page: page,
            size: size
        }
    })
    return response.data;
}

/**
 * 获取用户信息
 */

export async function getUserMessage(){

  const response = await  service.get('/springboot/user/getUser')
  return response.data;
}

/**
 * 订阅数据
 */
export async function subsribeMessage(topic,qos){

  const response = await  service.get('/springboot/SubsribeController/testSubsribe',{
    params:{
      topic:topic,
      qos:qos
    }
  })
  return response.data;
}

/**
 * 取消订阅数据
 */
export async function unSubsribeMessage(topic){

  const response = await  service.get('/springboot/SubsribeController/testUnsvSubsribe',{
    params:{
      topic:topic
    }
  })
  return response.data;
}/**
 * 订阅数据
 */
export async function subsribeMessageAndChange(topic,qos,msg){

    const response = await  service.get('/springboot/testPublish/testPublish',{
        params:{
            topic:topic,
            qos:qos,
            msg:msg
        }
    })
    return response.data;
}





