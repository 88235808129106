import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from "axios";

// ���� Vue Ӧ��ʵ��
const app = createApp(App)

// ʹ�� router �� ElementPlus ���
app.use(router)
app.use(ElementPlus)

// ע��ȫ�ֵ� Axios ʵ��
app.config.globalProperties.$axios = axios

// ���� Axios ʵ���Թ�����ģ��ʹ��
export { axios }

// ע������ ElementPlus ͼ�����
Object.entries(ElementPlusIconsVue).forEach(([name, component]) => {
    app.component(name, component)
})

// ����Ӧ�õ� DOM
app.mount('#app')



